import { actionCreatorFactory } from 'typescript-fsa'
import { asyncFactory } from 'typescript-fsa-redux-thunk'
import axios from 'axios';

import { StoreState } from './create-store'

interface UpdateValueParams {
  phase: keyof StoreState['resume']['values'];
  property: string;
  value: any;
}

const PREFIX = 'resume'

const actionCreator = actionCreatorFactory(PREFIX);
const asyncActionCreator = asyncFactory<StoreState>(actionCreator);

const editMode = actionCreator<{ isActive: boolean }>('EDIT_MODE')
const showPreview = actionCreator<boolean>('SHOW_PREVIEW')
const setPhase = actionCreator<{ phase: number | string; step: number, direction?: 1 | -1 }>('SET_PHASE')
const setComplete = actionCreator('SET_COMPLETE')
const setDefault = actionCreator<void>('SET_DEFAULT')
const nextStep = actionCreator('NEXT_STEP')
const prevStep = actionCreator('PREV_STEP')
const completeStep = actionCreator<{ index: number; }>('COMPLETE_STEP')
const loadingPortrait = actionCreator<boolean>('LOADING_PORTRAIT')
const reset = actionCreator('RESET')
const finishResume = actionCreator('FINISH')
const completeTour = actionCreator('COMPLETE_TOUR')
const updateValue = actionCreator<UpdateValueParams>('UPDATE_VALUE');

export const resumeActions = {
  completeStep,
  completeTour,
  editMode,
  finishResume,
  prevStep,
  nextStep,
  reset,
  setComplete,
  setDefault,
  setPhase,
  showPreview,
  updateValue,
  loadingPortrait,
};