import { reducerWithInitialState } from "typescript-fsa-reducers"
import { resumeActions } from "./actions"
import { StoreState } from "./create-store"
import { schema, getTotalSteps, schemaSteps } from '../partials/resume/schema'
import { findIndex, max, min, clamp } from "lodash-es"

export const initialState: StoreState["resume"]["status"] = {
  showIntro: true,
  editMode: false,
  isComplete: false,
  isTourComplete: false,
  totalSteps: getTotalSteps(),
  completedSteps: 0,
  completedPhases: 0,
  currentPhase: 'welcome',
  currentStep: -1,
  currentStepRelative: 0,
  editorDirection: -1,
  furthestAllowed: -1,
  furthestVisited: -1,
  showPrompt: false,
  showPreview: false,
}

export const resumeReducer = reducerWithInitialState(initialState)
  .case(resumeActions.editMode, (state, payload) => {
    return {
      ...state,
      editMode: payload.isActive,
      showIntro: !payload.isActive && state.showIntro,
    }
  })
  .case(resumeActions.setComplete, state => {
    return {
      ...state,
      isComplete: true,
    }
  })
  .case(resumeActions.completeTour, state => {
    return {
      ...state,
      isTourComplete: true,
    }
  })
  .case(resumeActions.setPhase, (state, payload) => {
    let absoluteIndex = payload.step;
    if (typeof payload.phase === 'number') {
      const step = schema.phases[payload.phase].steps[payload.step];
      absoluteIndex = findIndex(schemaSteps, ['id', step.id])
    }

    if (absoluteIndex > state.furthestAllowed) return state;

    const direction = payload.direction
      ? payload.direction
      : absoluteIndex > state.currentStep ? 1 : -1;

    return {
      ...state,
      currentPhase: payload.phase,
      currentStep: absoluteIndex,
      editorDirection: direction,
    }
  })
  .case(resumeActions.nextStep, (state) => {
    const isLastStep = state.currentStep === state.totalSteps - 1
    // Unable to advance steps
    if (state.currentStep >= state.furthestAllowed || isLastStep) return state;

    const nextIndex = state.currentStep + 1
    const nextStep = schemaSteps[nextIndex];

    const nextPhase = nextStep.phase
      ? findIndex(schema.phases, ['id', nextStep.phase])
      : null;

    return {
      ...state,
      currentStep: nextIndex,
      furthestVisited: max([state.furthestVisited, nextIndex]),
      currentPhase: nextPhase !== -1 ? nextPhase : state.currentPhase,
      showPrompt: false,
      editorDirection: 1,
    }
  })
  .case(resumeActions.prevStep, (state) => {
    const isFirstStep = state.currentStep === 0
    // Unable to advance steps
    if (isFirstStep || state.currentStep < 0) return state;

    const prevIndex = state.currentStep - 1
    const prevStep = schemaSteps[prevIndex];

    const prevPhase = prevStep.phase
      ? findIndex(schema.phases, ['id', prevStep.phase])
      : undefined;

    return {
      ...state,
      currentStep: prevIndex,
      currentPhase: prevPhase !== undefined ? prevPhase : state.currentPhase,
      editorDirection: -1,
    }
  })

  .case(resumeActions.completeStep, (state, payload) => {
    // if (payload.index < 0) return state; // dont run for 'welcome' and 'TOC' steps

    const completedStep = schemaSteps[payload.index]
    let furthestAllowed = max([payload.index + 1, state.furthestAllowed])
    furthestAllowed = clamp(furthestAllowed, state.totalSteps - 1)
    const completedSteps = max([state.completedSteps, payload.index])
    const completedPhases = completedStep
      ? max([state.completedPhases, findIndex(schema.phases, ['id', completedStep.phase])])
      : state.completedPhases

    return {
      ...state,
      furthestAllowed,
      completedSteps,
      completedPhases,
      isComplete: completedSteps === state.totalSteps - 1,
      // showPrompt: newFurthest > state.furthestAllowed
    }
  })

  .case(resumeActions.loadingPortrait, (state, payload) => {
    return {
      ...state,
      isLoadingPortrait: payload,
    }
  })

  .case(resumeActions.showPreview, (state, payload) => {
    return {
      ...state,
      showPreview: payload,
    }
  })

  .case(resumeActions.setDefault, (state, payload) => {
    return {
      ...state,
      showIntro: false,
      isComplete: true,
      furthestAllowed: state.totalSteps - 1,
      furthestVisited: state.totalSteps - 1,
      completedSteps: state.totalSteps - 1,
      completedPhases: schema.phases.length,
    }
  })

  .case(resumeActions.reset, (state, payload) => {
    return { ...initialState, isTourComplete: state.isTourComplete };
  })

  .case(resumeActions.finishResume, (state, payload) => {
    return {
      ...state,
      showIntro: false,
      editMode: false,
      isComplete: true,
      completedSteps: getTotalSteps() - 1,
      completedPhases: 2,
      furthestVisited: getTotalSteps() - 1,
      furthestAllowed: getTotalSteps() - 1,
    }
  })
