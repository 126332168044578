import { createStore, combineReducers, applyMiddleware, Dispatch } from "redux"
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware, { ThunkMiddleware } from 'redux-thunk'
import { load, save } from 'redux-localstorage-simple'
import { AnyAction } from 'typescript-fsa'
import { resumeReducer } from "./reducer"
import { resumeValueReducer } from "./values-reducer"
import { useDispatch } from 'react-redux'

interface Toggler {
  options: string[];
}

export interface StoreState {
  resume: {
    status: {
      completedSteps: number;
      completedPhases: number;
      currentPhase: string | number;
      currentStep: number;
      currentStepRelative: number;
      editMode: boolean;
      furthestAllowed: number;
      furthestVisited: number;
      isComplete: boolean;
      isTourComplete: boolean;
      showIntro: boolean;
      showPreview: boolean;
      showPrompt: boolean;
      totalSteps: number;
      editorDirection: 1 | -1;
    };
    values: {
      welcome: {
        intro: string;
        togglerIntro: number;
      },
      about: {
        introStyle: string;
        togglerPoem: number;
        togglerIntro: number;
        togglerElevator: number;
        togglerMinimal: number;
        togglerConfident: number;
        personal: string[];
        manifesto: string[];
        [key: string]: any;
      };
      portrait: {
        expression: string;
        facialHair: string;
        attire: string;
        hands: string;
        background: string;
        [key: string]: any;
      };
      experience: {
        skills: string[];
        past: string;
      };
    };
    static: {
      togglerPoem: Toggler;
      togglerIntro: Toggler;
      togglerElevator: Toggler;
      togglerMinimal: Toggler;
      togglerConfident: Toggler;
    };
  };
}

const staticState: StoreState['resume']['static'] = {
  togglerPoem: {
    options: [
      'oh yeah',
      'why not',
      'uh, meh',
      'no way'
    ]
  },
  togglerIntro: {
    options: [
      'create things with value',
      'make cool shit',
    ],
  },
  togglerElevator: {
    options: [
      'dribble, but with less gradients',
      'Squarespace, but with fewer promo codes',
      '99Designs, but not terrible',
      'Fiverr, but much more expensive... and also not terrible',
    ]
  },
  togglerMinimal: {
    options: [
      'maker of things',
      'maker',
      'human',
      'collection of atoms',
    ]
  },
  togglerConfident: {
    options: [
      'a bit of wit',
      'selective intellegence',
      'straight teeth',
    ]
  },
}

const reducer = combineReducers({
  resume: combineReducers({
    status: resumeReducer,
    values: resumeValueReducer,
    static: () => staticState,
  })
})

const thunk: ThunkMiddleware<StoreState, AnyAction> = thunkMiddleware
const middlewareEnhancer = applyMiddleware(thunk, save())
const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers)

const store = createStore(reducer, {}, composedEnhancers);

export const useThunkDispatch = () => useDispatch<typeof store.dispatch>();

// preloadedState will be passed in by the plugin
export default (preloadedState: StoreState) => {
  return createStore(reducer, getSavedState(preloadedState), composedEnhancers)
}


function getSavedState(preloadedState: StoreState | any) {
  if (typeof window !== 'undefined')
    return {
      ...preloadedState,
      ...load(),
    }

  return {
    ...preloadedState,
  }
}