import { reducerWithInitialState } from "typescript-fsa-reducers"
import { produce } from 'immer'
import axios from 'axios'

import { resumeActions } from "./actions"
import store, { StoreState } from "./create-store"
import { merge, isEqual, isArray, isObject } from "lodash-es"


const initialState: StoreState["resume"]["values"] = {
  welcome: {
    intro: '',
    togglerIntro: 0,
  },
  about: {
    introStyle: "",
    togglerPoem: 0,
    togglerIntro: 0,
    togglerElevator: 0,
    togglerMinimal: 0,
    togglerConfident: 0,
    personal: [],
    manifesto: [],
  },
  portrait: {
    expression: 'neutral',
    facialHair: '',
    hair: 'standard',
    background: '',
    attire: '',
    hands: '',
  },
  experience: {
    skills: [],
    past: '',
  },
}

export const defaultState: StoreState["resume"]["values"] = {
  welcome: {
    intro: '',
    togglerIntro: 0,
  },
  about: {
    introStyle: "standard",
    togglerPoem: 0,
    togglerIntro: 0,
    togglerElevator: 0,
    togglerMinimal: 0,
    togglerConfident: 0,
    personal: ['cooking', 'hiking', 'television', 'tennis', 'crafts', 'learn'],
    manifesto: ['craft', 'care', 'purpose', 'beyond', 'grow', 'climb', 'balance', 'enjoy'],
  },
  portrait: {
    expression: 'smile-3',
    facialHair: 'clean',
    hair: 'standard',
    background: 'white',
    attire: 'everyday',
    hands: 'default',
  },
  experience: {
    skills: ['print', 'digital', 'development', 'other'],
    past: 'detail',
  }
}

export const resumeValueReducer = reducerWithInitialState(initialState)
  .case(resumeActions.updateValue, (state, payload) => {
    return produce(state, (draft) => {
      if (draft[payload.phase][payload.property] !== undefined) {
        draft[payload.phase][payload.property] = payload.value
      }
    })
  })

  .case(resumeActions.setDefault, (state, payload) => {
    return defaultState;
  })

  .case(resumeActions.reset, (state, payload) => {
    return initialState
  })

  .case(resumeActions.finishResume, (state, payload) => {
    const userChanges = diff(initialState, state);
    return merge(defaultState, userChanges);
  })

function diff(obj1: Record<string, any>, obj2: Record<string, any>): Record<string, any> {
  let diffs: Record<string, any> = {}
  let key: string;

  const compare = function (item1: any, item2: any, key: string) {
    // Get the object type
    var type1 = Object.prototype.toString.call(item1);
    var type2 = Object.prototype.toString.call(item2);

    // If type2 is undefined it has been removed
    if (item2 === undefined) {
      return;
    }

    // If items are different types
    else if (type1 !== type2) {
      diffs[key] = item2;
      return;
    }

    // If an object, compare recursively
    else if (isObject(item1)) {
      var objDiff = diff(item1, item2);
      if (Object.keys(objDiff).length > 0) {
        diffs[key] = objDiff;
      }
      return;
    }

    // If an array, compare
    else if (isArray(item1)) {
      if (!isEqual(item1, item2)) {
        diffs[key] = item2;
      }
      return;
    }

    else if (item1 !== item2) {
      diffs[key] = item2;
    }

    return;
  }

  // Loop through the first object
  for (key in obj1) {
    if (obj1.hasOwnProperty(key)) {
      compare(obj1[key], obj2[key], key);
    }
  }

  return diffs;
}
